<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push({name: 'MyProfile'})"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.max-heart-rate')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-item>
        <ion-label>{{ __('interface.automatic') }}</ion-label>
        <ion-toggle slot="end" ref="automatic" :checked="form.automatic" @ionChange="changeAutomatic"></ion-toggle>
      </ion-item>
      <template v-if="!form.automatic">
        <ion-item>
          <ion-label position="floating">{{ __('interface.max-heart-rate') }}</ion-label>
          <ion-input v-model="form.max_heart_rate"/>
        </ion-item>
        <ion-button :disabled="form.max_heart_rate <= 0" expand="full" class="ion-margin-vertical" @click="actionSave">{{
            __('interface.save')
          }}
        </ion-button>
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonPage, IonToggle, loadingController, toastController} from "@ionic/vue";
import AuthService from "../AuthService";
import {API} from "@/utils/apiWrapper";

export default {
  name: "MaxHeartRate",
  mixins: [AuthService],
  components: {IonPage, IonToggle},
  data() {
    return {
      form: {
        automatic: true,
        max_heart_rate: 0
      },
      userInstance: {},
      loading: null
    }
  },
  methods: {
    changeAutomatic(e) {
      this.form.automatic = e.detail.checked

      if (this.form.automatic) {
        this.form.max_heart_rate = 0
        this.actionSave()
      }
    },
    async actionSave() {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      });
      await loading.present();

      await API.put('TheSpottApi', '/me-mhr', {body: this.form}).then(async response => {
        this.$store.commit('setUserMhr', response.data.max_heart_rate)
      }).then(async () => {
        await loading.dismiss();
        const toast = await toastController.create({
          message: this.__('message.saved-successfully'),
          duration: 2000
        })
        await toast.present();
        this.$tracking.event({
          eventName: 'add-mhr',
          category: 'profile'
        });
      }).catch(async () => {
        await loading.dismiss();
        const toast = await toastController.create({
          message: this.__('message.something-went-wrong'),
          duration: 2000
        })
        await toast.present();
      })
    }
  },
  async ionViewWillEnter() {
    this.loading = await loadingController.create({
      message: this.__('interface.loading'),
    });
    await this.loading.present();
  },
  async ionViewDidEnter() {
    this.userInstance = await this.user();
    this.form.automatic = this.userInstance.max_heart_rate <= 0
    this.form.max_heart_rate = this.userInstance.max_heart_rate
    await this.loading.dismiss();
  },
}
</script>